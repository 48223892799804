:root {
    --form-0216: #8133ff;
    --form-0158: rgba(246, 92, 78, 0.8);
    --form-0174: #FF33A6;
    --form-pit: rgba(51, 133, 255, 0.8);
    --form-0159: rgba(255, 51, 51, 0.8);
    --form-0160: rgba(33, 122, 69, 0.8);
    --form-0155: rgba(1, 56, 24, 0.8);
    --form-0175: rgb(234, 203, 166);
    --form-actuador-shafer: #BCC4DB;
    --form-actuador-eimlocal: #C0A9B0;
    --form-actuador-eimremoto: #7880B5;
    --form-actuador-hpllocal: #6987C9;
    --form-actuador-hplremoto: #6BBAEC;
    --form-actuador-hpglocal: #8D5B4C ;
    --form-actuador-hpgremoto: #C2A83E;
}